import { Optional } from '../types'
import { GetProduct } from './product'

type DataObject = {
  clientId?: number | unknown
  id?: number
}
// remove typical database fields that do not contain "data" about the type
export type DataFields<T extends DataObject> = Omit<
  Optional<T, 'id' | 'clientId'>,
  'active' | 'createdAt' | 'updatedAt'
>
export type ObjectFields<T> = Omit<T, 'active' | 'createdAt' | 'updatedAt'>

export type Product = {
  id: number

  // product details
  title?: string
  description?: string
  sku?: string
  notes?: string
  tags?: string
  condition?: string
  brand?: string
  category?: string
  cost?: number // float
  price?: number // float
  msrp?: number // float
  quantity?: number // integer
  sold?: number // integer
  vendor?: string
  wharehouse?: string
  location?: string
  bin?: string
  weight?: number
  weightUnit?: string
  width?: number
  length?: number
  height?: number
  sizeUnit?: string

  templateId: number // links to template

  active?: number
  createdAt?: string
  updatedAt?: string
}

export type ProductAttribute = {
  id: number
  productId: number
  templateAttributeId: number
  value: string | undefined
}

export type ProductTemplate = {
  id: number
  clientId: number
  name: string
  isStock?: boolean
  typeDescription?: string
  typeNotes?: string
  tags?: string
  title?: string
  description?: string
  notes?: string
  condition?: string
  brand?: string
  category?: string
  cost?: number // float
  price?: number // float
  msrp?: number // float
  quantity?: number // integer
  sold?: number // integer
  vendor?: string
  wharehouse?: string
  location?: string
  bin?: string
  weight?: number
  weightUnit?: string
  width?: number
  length?: number
  height?: number
  sizeUnit?: string
  products?: number
  nextProductId?: number
  active: number
  createdAt: string
  updatedAt: string
}

export type ProductAttributeInput = Omit<
  DataFields<ProductAttribute>,
  'id' | 'productId' | 'itemId'
>

export type ProductTemplateAttribute = {
  id: number
  templateId: number
  name: string
  type?: ProductTemplateAttributeTypes
  value?: string
  index: number
  constraint?: AttributeConstraintType | undefined
  active?: boolean
  createdAt?: string
  updatedAt?: string
}

type AttributeConstraintType = 'required' | 'recommended' | 'optional'
export enum AttributeConstraintEnum {
  REQUIRED = 'required',
  RECOMMENDED = 'recommended',
  OPTIONAL = 'optional',
}

export type ProductTemplateAttributeOption = {
  id: number
  templateId: number
  templateAttributeId: number
  locked?: boolean
  index: number
  type: 'text' | 'number' | 'block'
  value: string
}

export type ProductTemplateAttributeTypes = 'text' | 'number' | 'block'
export enum ProductTemplateAttributeTypesEnum {
  TEXT = 'text',
  NUMBER = 'number',
  BLOCK = 'block',
}

export type GetClientService = ClientService &
  Pick<Service, 'name' | 'displayName' | 'description'>

export type Service = {
  id: number
  name: string
  displayName: string
  category: string
  type: string
  description: string
  fee: number
  billing: string
  active?: boolean
}
export type ClientService = {
  id: number
  clientId: number
  serviceId: number
  fee: number
  billing: string
  notes: string
  fetchTags: string
  addTags: string
  removeTags: string
  active?: boolean
}

export type Client = {
  id: number
  name: string
  displayName: string
  description: string
  active: string
  createdAt: string
  updatedAt: string
}

export type InventoryColumn = {
  id: number
  clientId: number
  templateId?: number
  columns: string
  active: number
  createdAt: string
  updatedAt: string
}

export type Integration = {
  id: number
  name: string
  shop?: string
  description?: string
  clientId: number
  authenticationType: string
  active: boolean
  createdAt: string
  updatedAt: string
}

export type LoginAuthentication = {
  id: number
  clientId: number
  name: string
  type: string
  email: string
  username: string
  password: string
  phone: string
  active: boolean
  createdAt: string
  updatedAt: string
}

export type OAuthAuthentication = {
  id: number
  integrationId: number
  clientId: number
  name: string
  type?: string
  accessToken: string
  refreshToken?: string
  expires?: string
  refreshExpires?: string
  active: boolean
  createdAt: string
  updatedAt: string
}

export type ProductImage = {
  id: number
  productId: number
  url: string
  index: number
  active: number
  createdAt: string
  updatedAt: string
}

export type ProductVariation = {
  id: number
  productId: number
  name: string
  sku: string
  quantity?: number
  sold: number
  price?: number
  cost?: number
  index?: number
}

export type ProductVariationAttribute = {
  id: number
  productVariationId: number
  templateAttributeId: number
  templateVariationAttributeId: number
  value: string
}

export type ProductVariationImage = {
  id: number
  productVariationId: number
  url: string
  index: number
}

export type TemplateVariationAttribute = {
  id: number
  templateId: number
  templateAttributeId: number
  index: number
}

export type ProductListing = {
  id: number
  clientId: number
  integrationId: number
  productId: number
  channel: string
  externalId: string
  status?: string
  dateListed?: string
  dateUpdated?: string
  active?: boolean
}

export type User = {
  id: number
  uuid: string
  name: string
  email: string
  emailConfirmed?: boolean
  phone: string
  image?: string
  title: string
  description: string
  code?: string
  codeExpires?: string
  pass?: string // optional for security
  salt?: string // optional for security
  active?: boolean
  created?: string
}

export type Role = {
  id: number
  clientId: number
  userId: number
  roleId: number
  status?: string
  active: boolean
}

export type Order = {
  id?: number
  clientId: number
  channel: string

  // Order Details
  source?: string // source details if available
  externalOrderId: string // ? or externalOrderId
  orderDate?: string
  updateDate?: string
  orderNotes?: string

  readyToShip?: string

  // Payment Details
  orderStatus?: string
  paymentStatus?: string
  paymentMethod?: string
  paymentDate?: string
  paymentAmount?: number
  currency?: string
  // Cancel Details
  cancelStatus?: string
  // Refund Details
  refundStatus?: string

  // Shipping Details
  shippingStatus?: string
  shippingMethod?: string
  shippingDate?: string
  shippingCost?: number
  trackingNumber?: string

  // Price Amount
  totalAmount?: number // total
  discountAmount?: number // discount
  feeAmount?: number // fees
  taxAmount?: number // taxes
  netAmount?: number // totalAmount - (fees, discount, tax)

  // Item Totals
  totalPrice: number // items.price sum
  totalCost: number // items.cost sum
  totalQuantity: number // items.quantity sum

  items?: OrderItem[]

  active?: boolean
  createdAt?: string
  updatedAt?: string
  rows?: number
}

export type OrderItem = {
  id?: number
  orderId?: string

  templateId: number
  productId: number
  productVariationId?: number
  sku: string

  externalId?: string // if available

  price: number // price of product at time of sale
  cost: number // cost of product at time of sale
  quantity: number // number sold

  product?: Product
  getProduct?: GetProduct
  productImages?: ProductImage[]
}

export type StockTemplate = {
  id: number
  name: string
  description?: string
  category: string
  subCategory?: string
  tags?: string
  templateId: number
  valueMapping?: boolean
  allChannels?: boolean
  channels?: string[]
  channelTemplates?: Record<string, string[]>
  active: boolean
  createdAt: string
  updatedAt: string
}
