import React, { useContext, useEffect, useState, useRef } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import NavBar from '../../components/common/NavBar'
import ClientSelect from '../../components/admin/ClientSelect'
import CreateClient from '../../components/admin/CreateClient'
import AddUserRole from '../../components/admin/AddUserRole'
import UpdateClient from '../../components/admin/UpdateClient'
import { getClient } from '../../api/admin'
import Container from '@mui/material/Container'
import { err, isAdmin, isSuperAdmin } from '../../utils/functions'
import AppContext from '../../app/AppContext'
import { Client } from '../../api/types'
import Component from '../../components/common/Component'
import Box from '@mui/joy/Box'
import { Link } from 'react-router-dom'
import ClientUsers from '../../components/admin/ClientUsers'

export default function Admin(): JSX.Element {
  const { appState } = useContext(AppContext)
  const isMounted = useRef(true)
  const roleId = appState.user.roleId
  const clientId = appState.client.id
  const [client, setClient] = useState<Client>()
  useEffect(() => {
    if (isMounted.current) {
      if (clientId) {
        getClient({ id: clientId })
          .then((res) => {
            if (res?.data) {
              setClient(res.data)
            }
          })
          .catch((e) => err(e))
      }
      return () => {
        isMounted.current = false
      }
    }
  }, [clientId])

  if (!clientId) {
    return (
      <div
        style={{
          width: '100%',
        }}
      >
        <NavBar />
        <Container maxWidth={'xl'}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{
              padding: '1em',
            }}
          >
            <Grid item xs={12}>
              <Component>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <Typography>
                      You do not belong to an Organization. Create one below or
                      check your <Link to="/account">Invites</Link>.
                    </Typography>
                  </Grid>
                </Grid>
              </Component>
            </Grid>
            <Grid item xs={12}>
              <CreateClient />
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }

  // TODO snackbar alert handling
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container maxWidth={'xl'}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{
            padding: '1em',
          }}
        >
          {isSuperAdmin(roleId) && (
            <>
              {/* <Grid item xs={12} md={4}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <AllClients />
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid item xs={12}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={12}>
                    <ClientSelect />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {client && isAdmin(roleId) ? (
            <>
              <Grid item xs={12} md={12}>
                <UpdateClient client={client} />
              </Grid>
              <Grid item xs={12} md={12}>
                <ClientUsers clientId={clientId} />
              </Grid>
              <Grid item xs={12} md={12}>
                <AddUserRole clientId={clientId} />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Component>
                <Typography>
                  You do not have sufficient permissions to access admin
                  features.
                </Typography>
              </Component>
            </Grid>
          )}
        </Grid>
      </Container>
      <Box p={16}></Box>
    </div>
  )
}
