import React, { Fragment, useEffect, useRef } from 'react'
import Button from '@mui/joy/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { useState } from 'react'
import {
  createProductTemplate,
  CreateProductTemplateInput,
  GetProductTemplateAttribute,
  getStockTemplates,
  importStockTemplate,
  SetProductTemplateAttribute,
} from '../../api/product'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import Box from '@mui/material/Box'
import { useHistory } from 'react-router-dom'
import { err, formatDate, wait } from '../../utils/functions'
import { newAttribute } from './ProductTemplate'
import EbayCategories, {
  EbayCategory,
} from '../../components/product/integrations/ebay/EbayCategories'
import { ebayCreateTemplate } from '../../api/integrations/ebay'
import LabelInput from '../../components/common/LabelInput'
import LabelTextarea from '../../components/common/LabelTextarea'
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab from '@mui/joy/Tab'
import TabPanel from '@mui/joy/TabPanel'
import { IntegrationDisplayName, IntegrationName } from '../../api/integration'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/joy/IconButton'
import List from '@mui/joy/List'
import ListDivider from '@mui/joy/ListDivider'
import ListItem from '@mui/joy/ListItem'
import ListItemContent from '@mui/joy/ListItemContent'
import Textarea from '@mui/joy/Textarea'
import { useTheme } from '@mui/joy'
import JoyDataTable from '../../components/common/JoyDataTable'
import { StockTemplate } from '../../api/types'
import Chip from '@mui/joy/Chip'
import Avatar from '@mui/joy/Avatar'
import AvatarGroup from '@mui/joy/AvatarGroup'
import Modal from '@mui/joy/Modal'
import ModalClose from '@mui/joy/ModalClose'
import ModalDialog from '@mui/joy/ModalDialog'
import Tooltip from '@mui/joy/Tooltip'

import InputContainer from '../../components/common/InputContainer'
import StorefrontIcon from '@mui/icons-material/Storefront'
import ChecklistIcon from '@mui/icons-material/Checklist'

import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const STOCK_ATTRIBUTES = [
  'Brand',
  // 'Category',
  // 'Condition Description',
  'Vendor',
  'Location',
  'Warehouse',
  'Bin',
]

export function getStockAttributes(
  attributes: GetProductTemplateAttribute[],
): SetProductTemplateAttribute[] {
  const existingAttributeNames = attributes.map((a) => a.attribute.name)
  const stockAttributes = STOCK_ATTRIBUTES.filter(
    (name) => !existingAttributeNames.includes(name),
  ).map((name, i) =>
    newAttribute({
      name,
      templateId: attributes[0]?.attribute.templateId,
      index: i,
    }),
  )
  return stockAttributes
}

export default function CreateTemplate(): JSX.Element {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.down('md'))

  const history = useHistory()
  const isMounted = useRef(true)
  // Product Template
  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [notes, setNotes] = useState<string | undefined>()
  const [tags, setTags] = useState<string>('')

  const [stockTemplates, setStockTemplates] = useState<
    StockTemplate[] | undefined
  >()

  const [templateId, setProductTemplateId] = useState<number>()
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [category, setCategory] = useState<EbayCategory | undefined>()
  const [categoryVersion, setCategoryVersion] = useState<number>(0)

  const [createMethod, setCreateMethod] = useState<string>('create')

  const [selectedStockTemplate, setSelectedStockTemplate] = useState<
    StockTemplate | undefined
  >()
  const [stockTemplateDetailsModalOpen, setStockTemplateDetailsModalOpen] =
    useState<boolean>(false)

  const handleSetCreateMethod = (method: string) => {
    if (!method) return
    setCreateMethod(method)

    if (method === 'create' && category) {
      setCategory(undefined)
      setName('')
    }
  }

  const handleSelectCategory = (
    categoryVersion: number,
    category: EbayCategory | undefined,
    path?: string[],
  ) => {
    const name = path ? path?.join(' > ') : category?.name
    setCategoryVersion(categoryVersion)
    setCategory(category)
    if (category) {
      setDescription(`Based off Ebay ${name} - ${category.id}`)
    }
  }

  const handleImportStockTemplate = (id?: number) => {
    const stockTemplateId = id || selectedStockTemplate?.id

    if (!stockTemplateId) return

    importStockTemplate(stockTemplateId)
      .then(async (res) => {
        handleAlert(
          setAlert,
          res,
          'Successfully imported template. Redirecting...',
        )

        if (res.success) {
          await wait(2000)
          history.push(`/template/${res.data}`)
        }
      })
      .catch((e) => {
        err(e)
        handleAlert(setAlert, e.response.data, 'Error')
      })
  }

  useEffect(() => {
    if (stockTemplates !== undefined || createMethod !== 'library') {
      return
    }
    // get stock templates
    getStockTemplates()
      .then((res) => {
        if (res.success && res.data) {
          setStockTemplates(res.data)
        }
      })
      .catch((e) => err(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createMethod])

  useEffect(() => {
    if (templateId !== undefined) {
      history.push(`template/${templateId}`)
    }
  }, [history, templateId])

  const handleSubmit = () => {
    if (isMounted.current) {
      if (!name.trim()) {
        setNameError('Name field is required.')
        return
      }

      if (category?.id && name) {
        ebayCreateTemplate(category.id, name)
          .then(async (res) => {
            if (res?.success) {
              const templateId = res.data?.template.id
              if (templateId) {
                setAlert({
                  open: true,
                  message: 'Template Created.',
                  severity: 'success',
                })

                await wait(1000)
                setProductTemplateId(templateId)
              } else {
                setAlert({
                  open: true,
                  message: 'Unknown response from server.',
                  severity: 'error',
                })
              }
            } else {
              setAlert({
                open: true,
                message: res?.message || 'Error',
                severity: 'error',
              })
            }
          })
          .catch((e) => {
            setAlert({
              open: true,
              message: e?.message || 'Error',
              severity: 'error',
            })
          })

        return
      }

      const templateAttributes = STOCK_ATTRIBUTES.map(
        (name, i) =>
          newAttribute({ name, value: '', type: 'text', index: i }).attribute,
      )
      const template: CreateProductTemplateInput = { name, description }
      createProductTemplate(template, templateAttributes)
        .then((result) => {
          if (result?.success) {
            const templateId = result.data?.id
            if (templateId) {
              setAlert({
                open: true,
                message: 'Template Created.',
                severity: 'success',
              })
              setProductTemplateId(templateId)
            } else {
              setAlert({
                open: true,
                message: 'Unknown response from server.',
                severity: 'error',
              })
            }
          } else {
            setAlert({
              open: true,
              message: result?.message || 'Error',
              severity: 'error',
            })
          }

          return () => {
            isMounted.current = false
          }
        })
        .catch((e) => err(e))
    }
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container sx={{ marginTop: 4 }} maxWidth={'xl'}>
        <Component>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography level="h3">Create a Template</Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <ToggleButtonGroup
                  orientation={md ? 'vertical' : 'horizontal'}
                  value={createMethod}
                  onChange={(e, v) => {
                    handleSetCreateMethod(v as string)
                  }}
                >
                  <Button value="create">
                    <Box p={3} sx={{ width: md ? '250px' : '280px' }}>
                      <Typography level="title-lg" component="p">
                        From Scratch
                      </Typography>
                      <Typography level="body-xs" component="p">
                        Create a template from scratch
                      </Typography>
                    </Box>
                  </Button>
                  <Button value="build">
                    <Box p={3} sx={{ width: md ? '250px' : '280px' }}>
                      <Typography level="title-lg">Channel Based</Typography>
                      <Typography level="body-xs">
                        Base on an external channel category
                      </Typography>
                    </Box>
                  </Button>
                  <Button value="library">
                    <Box p={3} sx={{ width: md ? '250px' : '280px' }}>
                      <Typography level="title-lg">Template Library</Typography>
                      <Typography level="body-xs" color="neutral">
                        Library of pre-configured templates
                      </Typography>
                    </Box>
                  </Button>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            {createMethod === 'create' ? (
              <Grid item xs={12} mt={4}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      label="Template Name"
                      required
                      value={name}
                      fullWidth
                      onChange={setName}
                      errorText={nameError}
                      onClick={() => {
                        setNameError('')
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <LabelTextarea
                      label="Template Description"
                      minRows={10}
                      value={description}
                      onChange={setDescription}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <LabelTextarea
                      label="Notes"
                      minRows={5}
                      value={notes}
                      onChange={setNotes}
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <LabelInput
                      label="Tags"
                      fullWidth
                      value={tags}
                      onChange={setTags}
                      helperText="Comma separated"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                      <Button onClick={() => handleSubmit()} variant="solid">
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {createMethod === 'build' ? (
              <>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={2} mt={4}>
                    <Grid item xs={12}>
                      <Typography level="title-lg">
                        Select Channel and Category
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Tabs
                          size="lg"
                          defaultValue={IntegrationName.EBAY}
                          variant="outlined"
                        >
                          <TabList>
                            <Tab
                              variant="plain"
                              color="neutral"
                              value={IntegrationName.EBAY}
                            >
                              Ebay
                            </Tab>
                            <Tab
                              variant="plain"
                              color="neutral"
                              value={IntegrationName.ETSY}
                            >
                              Etsy
                            </Tab>
                          </TabList>
                          <TabPanel value={IntegrationName.EBAY} sx={{ p: 1 }}>
                            <Grid
                              container
                              justifyContent="center"
                              spacing={2}
                              sx={{ px: 0, py: 2 }}
                            >
                              <Grid item xs={12}>
                                <EbayCategories
                                  category={category}
                                  categoryVersion={categoryVersion}
                                  onChange={handleSelectCategory}
                                />
                              </Grid>
                              {category && (
                                <>
                                  <Grid item xs={12}>
                                    <Typography level="body-xs">
                                      Template will create Custom Attributes for
                                      all aspects in Ebay category{' '}
                                      {category.name}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent="center"
                                    >
                                      <Grid item xs={12}>
                                        <LabelInput
                                          label="Template Name"
                                          required
                                          value={name}
                                          fullWidth
                                          onChange={setName}
                                          errorText={nameError}
                                          onClick={() => {
                                            setNameError('')
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <LabelTextarea
                                          label="Template Description"
                                          minRows={10}
                                          value={description}
                                          onChange={setDescription}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <LabelTextarea
                                          label="Notes"
                                          minRows={5}
                                          value={notes}
                                          onChange={setNotes}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <LabelInput
                                          label="Tags"
                                          fullWidth
                                          value={tags}
                                          onChange={setTags}
                                          helperText="Comma separated"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </TabPanel>
                          <TabPanel value={IntegrationName.ETSY} sx={{ p: 1 }}>
                            <Grid
                              container
                              justifyContent="center"
                              spacing={1}
                              sx={{ px: 0, py: 2 }}
                            >
                              <Grid item xs={12}>
                                <Typography>
                                  Etsy Build a Template will be available soon.
                                </Typography>
                              </Grid>
                            </Grid>
                          </TabPanel>
                        </Tabs>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}

            {createMethod === 'library' ? (
              <Grid item xs={12} mt={4}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography level="title-lg">
                          Template Library
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <InputContainer>
                              <JoyDataTable
                                data={stockTemplates || []}
                                // onSelection={(selectedRows) =>
                                //   setSelectedOrders?.(selectedRows)
                                // }
                                renderRow={(row) => (
                                  <Fragment key={row.id}>
                                    <td>{row.name}</td>
                                    <td>
                                      <Typography level="body-sm">
                                        {row.category}
                                      </Typography>
                                      <Typography level="body-xs">
                                        {row.subCategory}
                                      </Typography>
                                    </td>
                                    <td>
                                      {row.tags
                                        ? row.tags.split(',').map((tag, i) => {
                                            return (
                                              <Chip
                                                key={tag + i}
                                                sx={{
                                                  ml: 0.33,
                                                }}
                                                size="sm"
                                                color="neutral"
                                              >
                                                {tag}
                                              </Chip>
                                            )
                                          })
                                        : ''}
                                    </td>
                                    <td>
                                      <AvatarGroup>
                                        {row.channels?.map((channel) => {
                                          const iconSrc = `/channel_icons/${channel}.png`
                                          return (
                                            <Avatar
                                              style={{
                                                border: '1px solid #eee',
                                              }}
                                              sx={{
                                                width: 24,
                                                height: 24,
                                              }}
                                              key={channel}
                                              alt={channel}
                                              src={iconSrc}
                                            />
                                          )
                                        }) || null}
                                      </AvatarGroup>
                                    </td>
                                    <td>
                                      {row.allChannels ? (
                                        <Tooltip
                                          title="Works with all channels"
                                          size="sm"
                                        >
                                          <Chip
                                            sx={{
                                              ml: 0.33,
                                            }}
                                            size="sm"
                                            color="success"
                                            startDecorator={
                                              <StorefrontIcon fontSize="small" />
                                            }
                                          ></Chip>
                                        </Tooltip>
                                      ) : null}
                                      {row.valueMapping ? (
                                        <Tooltip
                                          title="Features value mapping"
                                          size="sm"
                                        >
                                          <Chip
                                            sx={{
                                              ml: 0.33,
                                            }}
                                            size="sm"
                                            color="success"
                                            startDecorator={
                                              <ChecklistIcon fontSize="small" />
                                            }
                                          ></Chip>
                                        </Tooltip>
                                      ) : null}
                                    </td>
                                    {/* <td>
                                      <Typography level="title-sm">
                                        {formatDate(row.createdAt)}
                                      </Typography>
                                    </td> */}
                                    <td>
                                      <Tooltip title="View Details">
                                        <IconButton
                                          size="sm"
                                          color="neutral"
                                          variant="plain"
                                          onClick={() => {
                                            setStockTemplateDetailsModalOpen(
                                              true,
                                            )
                                            setSelectedStockTemplate(row)
                                          }}
                                        >
                                          <InfoOutlinedIcon
                                            color="action"
                                            fontSize="small"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        color="neutral"
                                        variant="plain"
                                        onClick={() =>
                                          handleImportStockTemplate(row.id)
                                        }
                                        endDecorator={
                                          <ArrowCircleDownIcon
                                            color="action"
                                            fontSize="small"
                                          />
                                        }
                                      >
                                        Import
                                      </Button>
                                    </td>
                                  </Fragment>
                                )}
                                columns={[
                                  {
                                    key: 'name',
                                    label: 'Name',
                                    sortable: true,
                                    width: '150px',
                                  },
                                  {
                                    key: 'category',
                                    label: 'Category',
                                    sortable: true,
                                    width: '80px',
                                  },
                                  {
                                    key: 'tags',
                                    label: 'Tags',
                                    sortable: true,
                                    width: '80px',
                                  },
                                  {
                                    key: 'channels',
                                    label: 'Channels',
                                    sortable: true,
                                    width: '90px',
                                  },
                                  {
                                    key: 'valueMapping',
                                    label: 'Features',
                                    sortable: true,
                                    width: '60px',
                                  },
                                  // {
                                  //   key: 'createdAt',
                                  //   label: 'Created',
                                  //   sortable: true,
                                  //   width: '80px',
                                  // },
                                  {
                                    key: 'name',
                                    label: 'Details',
                                    width: '40px',
                                  },
                                  {
                                    key: 'name',
                                    label: 'Action',
                                    width: '80px',
                                  },
                                ]}
                                selectable={false}
                              />
                            </InputContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            <Box sx={{ height: '100px' }} />
          </Grid>
        </Component>
        <Box p={30}></Box>
      </Container>
      <Modal
        open={stockTemplateDetailsModalOpen}
        onClose={() => {
          setStockTemplateDetailsModalOpen(false)
          setSelectedStockTemplate(undefined)
        }}
      >
        <ModalDialog
          layout="center"
          sx={{ overflow: 'auto', p: 2 }}
          minWidth="lg"
        >
          <ModalClose />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography level="h4">Add to Library</Typography>
            </Grid>
            <Grid item xs={12}>
              {selectedStockTemplate?.allChannels ? (
                <Chip
                  sx={{
                    ml: 2,
                  }}
                  color="success"
                  startDecorator={<StorefrontIcon fontSize="small" />}
                >
                  Works with all Channels
                </Chip>
              ) : null}
              {selectedStockTemplate?.valueMapping ? (
                <Chip
                  sx={{
                    ml: 2,
                  }}
                  color="success"
                  startDecorator={<ChecklistIcon fontSize="small" />}
                >
                  Features value mapping
                </Chip>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <List variant="outlined">
                <ListItem>
                  <Typography textAlign="left" level="title-sm">
                    <strong>Name</strong>
                  </Typography>
                  <ListItemContent>
                    <Typography textAlign="left" level="body-md">
                      {selectedStockTemplate?.name}
                    </Typography>
                  </ListItemContent>
                </ListItem>
                <ListDivider />
                <ListItem>
                  <Typography textAlign="left" level="title-sm">
                    <strong>Categories</strong>
                  </Typography>
                  <ListItemContent>
                    <Typography textAlign="left" level="body-md">
                      {selectedStockTemplate?.category}
                      <Typography
                        textAlign="left"
                        level="body-xs"
                        sx={{ ml: 2 }}
                      >
                        {selectedStockTemplate?.subCategory}
                      </Typography>
                    </Typography>
                  </ListItemContent>
                </ListItem>
                <ListDivider />
                <ListItem>
                  <Typography textAlign="left" level="title-sm">
                    <strong>Tags</strong>
                  </Typography>
                  <ListItemContent>
                    <Typography component="div" textAlign="left">
                      {selectedStockTemplate?.tags?.split(',').map((tag, i) => {
                        return (
                          <Chip
                            key={tag + i}
                            sx={{
                              ml: 0.33,
                            }}
                            color="neutral"
                          >
                            {tag}
                          </Chip>
                        )
                      })}
                    </Typography>
                  </ListItemContent>
                </ListItem>
                <ListDivider />
                <ListItem>
                  <Typography textAlign="left" level="title-sm">
                    <strong>Channels</strong>
                    <Typography level="body-xs" display="block">
                      Channel templates
                    </Typography>
                  </Typography>

                  <ListItemContent>
                    <Typography component="div" textAlign="left">
                      {selectedStockTemplate?.channels?.map((c, i) => {
                        return (
                          <Tooltip
                            key={c + i}
                            title={
                              selectedStockTemplate.channelTemplates?.[c] ? (
                                <Typography>
                                  {selectedStockTemplate.channelTemplates?.[
                                    c
                                  ]?.map((channelTemplate) => {
                                    return (
                                      <Typography
                                        key={channelTemplate}
                                        display="block"
                                      >
                                        {channelTemplate}
                                      </Typography>
                                    )
                                  })}
                                </Typography>
                              ) : (
                                ''
                              )
                            }
                          >
                            <Chip
                              sx={{
                                ml: 0.33,
                                cursor: 'pointer',
                              }}
                              color="neutral"
                            >
                              {IntegrationDisplayName[c]}
                            </Chip>
                          </Tooltip>
                        )
                      })}
                    </Typography>
                  </ListItemContent>
                </ListItem>
                <ListDivider />
                <ListItem>
                  <ListItemContent>
                    <Typography textAlign="left" level="title-sm">
                      <strong>Description</strong>
                    </Typography>
                    <Textarea
                      sx={{ mt: 1 }}
                      value={selectedStockTemplate?.description || ''}
                      minRows={10}
                    ></Textarea>
                  </ListItemContent>
                </ListItem>
                <ListDivider />
                <ListItem>
                  <ListItemContent>
                    <Typography display="block" textAlign="left">
                      <Typography textAlign="left" level="body-xs">
                        <strong>Created</strong>
                      </Typography>
                      <Typography level="body-xs" textAlign="left" ml={1}>
                        {selectedStockTemplate?.updatedAt
                          ? formatDate(selectedStockTemplate?.createdAt)
                          : ''}
                      </Typography>
                    </Typography>

                    <Typography level="body-sm" textAlign="left">
                      <Typography display="block">
                        <Typography textAlign="left" level="body-xs">
                          <strong>Last Updated</strong>
                        </Typography>
                        <Typography level="body-xs" textAlign="left" ml={1}>
                          {selectedStockTemplate?.updatedAt
                            ? formatDate(selectedStockTemplate?.updatedAt)
                            : ''}
                        </Typography>
                      </Typography>
                    </Typography>
                  </ListItemContent>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ float: 'right' }}
                color="neutral"
                variant="soft"
                onClick={() => handleImportStockTemplate()}
                endDecorator={
                  <ArrowCircleDownIcon color="action" fontSize="small" />
                }
              >
                Import
              </Button>
            </Grid>
          </Grid>
        </ModalDialog>
      </Modal>
      <Alert
        alert={alert}
        onClose={() => setAlert({ ...alert, open: false })}
      ></Alert>
    </div>
  )
}
