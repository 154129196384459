import React, { useContext, useMemo } from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/joy/Typography'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import {
  deleteProductTemplate,
  GetProductTemplate,
  getProductTemplate,
  ProductTemplateInput,
  updateProductTemplate,
  SetProductTemplateAttribute,
  duplicateTemplate,
  mergeTemplateAttributes,
} from '../../api/product'
import Component from '../../components/common/Component'
import NavBar from '../../components/common/NavBar'
import {
  DataFields,
  ProductTemplateAttribute,
  ProductTemplateAttributeOption,
  ProductTemplateAttributeTypes,
  ProductTemplateAttributeTypesEnum,
} from '../../api/types'
import Box from '@mui/material/Box'
import Button from '@mui/joy/Button'
import Stack from '@mui/material/Stack'
import Tabs from '../../components/common/Tabs'
import { ProductTemplateAttributeInputField } from './ProductTemplateAttributeInputField'
import Alert, { AlertInput, handleAlert } from '../../components/common/Alert'
import {
  GetIntegrationsResult,
  getAvailableIntegrations,
  getIntegrations,
} from '../../api/integration'
import { err, getAttributeTag, isSuperAdmin, wait } from '../../utils/functions'
import EbayIntegrationsComponent from '../../components/product/integrations/ebay/EbayIntegrationsComponent'
import ProductTemplateDefaultAttributeInputField from './ProductTemplateDefaultAttributeInputField'
import { ProductDescriptionEditor } from '../products/ProductDescriptionEditor'
import { STOCK_ATTRIBUTES } from './CreateTemplate'
import Autocomplete from '@mui/joy/Autocomplete'
import ProductTags from '../products/product/ProductTags'
import SquareIntegrationsComponent from '../../components/product/integrations/square/SquareIntegrationsComponent'
import Modal from '../../components/common/Modal'
import Draggable from '../../components/common/Draggable'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/joy/Switch'
import SingleSelect from '../../components/common/SingleSelect'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LabelInput from '../../components/common/LabelInput'
import LabelTextarea from '../../components/common/LabelTextarea'
import EditIcon from '@mui/icons-material/Edit'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import SaveIcon from '@mui/icons-material/Save'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import Add from '@mui/icons-material/Add'
import EtsyIntegrationsComponent from '../../components/product/integrations/etsy/EtsyIntegrationsComponent'
import ShopifyIntegrationsComponent from '../../components/product/integrations/shopify/ShopifyIntegrationsComponent'
import BigCommerceIntegrationsComponent from '../../components/product/integrations/bigcommerce/BigCommerceIntegrationsComponent'
import CloverIntegrationsComponent from '../../components/product/integrations/clover/CloverIntegrationsComponent'
import CSVIntegrationsComponent from '../../components/product/integrations/csv/CSVIntegrationsComponent'
import { EbayAttributeAspectValues } from '../../types/Ebay.types'
import { getEbayAttributeAspectValues } from '../../api/integrations/ebay'
import { getEtsyAttributePropertyValues } from '../../api/integrations/etsy'
import ProductSizeWeightInput, {
  SizeWeightChangeEnum,
} from '../../components/product/ProductSizeWeightInput'
import FloatLabelInput from '../../components/common/FloatLabelInput'
import AppContext from '../../app/AppContext'

export function newAttribute(opts: {
  name?: string
  value?: string
  type?: ProductTemplateAttributeTypes
  index: number
  templateId?: number
}): SetProductTemplateAttribute {
  return {
    attribute: {
      name: opts?.name || '',
      value: opts?.value || '',
      type: opts?.type || 'text',
      index: opts.index,
    },
    attributeOptions: [],
  }
}

export function newOption(opts?: {
  name?: string
  value?: string
  type?: ProductTemplateAttributeTypes
  index?: number
}): Omit<
  DataFields<ProductTemplateAttributeOption>,
  'templateId' | 'templateAttributeId'
> {
  return {
    value: opts?.value || '',
    type: opts?.type || 'text',
    index: opts?.index || 0,
  }
}

export default function ProductTemplate(): JSX.Element {
  const {
    templateId: templateIdParam,
    tab,
    channel,
  } = useParams<{
    templateId?: string
    tab?: string
    channel?: string
  }>()
  const { appState } = useContext(AppContext)
  const roleId = appState.user.roleId
  const history = useHistory()
  const setSelectedTab = (tab: string) => {
    history.push(`/template/${templateId}/${tab}`, { shallow: true })
  }
  const setSelectedChannel = (channel: string) => {
    history.push(`/template/${templateId}/${tab}/${channel}`, { shallow: true })
  }

  const [descriptionUpdates, setDescriptionUpdates] = useState<number>(0)

  const [loading, setLoading] = useState<boolean>(false)
  const [update, setUpdate] = useState<number>(0)
  const templateId = templateIdParam ? parseInt(templateIdParam) : undefined
  const isMounted = useRef(true)
  const [alert, setAlert] = useState<AlertInput>({ open: false })

  const [richText, setRichText] = useState<boolean>(true)

  // Product Template
  const [template, setTemplate] = useState<GetProductTemplate>()

  const [name, setName] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const [typeDescription, setTypeDescription] = useState<string>('')
  const [typeNotes, setTypeNotes] = useState<string>('')
  const [isStock, setIsStock] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [typeTags, setTypeTags] = useState<string[]>([])

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  // Product Template Attributes
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const [tags, setTags] = useState<string[]>([])
  const [weightUnit, setWeightUnit] = useState<string>('')
  const [sizeUnit, setSizeUnit] = useState<string>('')
  const [length, setLength] = useState<string | undefined>()
  const [width, setWidth] = useState<string | undefined>()
  const [height, setHeight] = useState<string | undefined>()
  const [weight, setWeight] = useState<string | undefined>()

  const [reorderAttributes, setReorderAttributes] = useState<boolean>(false)

  const [templateVariationAttributes, setTemplateVariationAttributes] =
    useState<DataFields<ProductTemplateAttribute>[]>([])

  // integrations
  const [integrations, setIntegrations] = useState<GetIntegrationsResult>({})
  const [availableIntegrations, setAvailableIntegrations] = useState<string[]>(
    [],
  )

  const [mergeAttributesDeleteSource, setMergeAttributesDeleteSource] =
    useState<boolean>(false)
  const [mergeAttributesAction, setMergeAttributesAction] = useState<string>('')
  const [mergeAttributesModalOpen, setMergeAttributesModalOpen] =
    useState<boolean>(false)
  const [mergeAttributeSource, setMergeAttributeSource] = useState<
    SetProductTemplateAttribute | undefined
  >(undefined)
  const [mergeAttributeTarget, setMergeAttributeTarget] = useState<
    SetProductTemplateAttribute | undefined
  >(undefined)
  const [confirmDeleteName, setConfirmDeleteName] = useState<string>('')

  // Editing and Adding Attributes
  const [templateAttributes, setTemplateAttributes] = useState<
    SetProductTemplateAttribute[]
  >([])

  const editableAttributes = useMemo(() => {
    return templateAttributes.filter(
      (a) => !STOCK_ATTRIBUTES.includes(a.attribute.name),
    )
  }, [templateAttributes])

  const [attributeAspectValues, setAttributeAspectValues] = useState<
    EbayAttributeAspectValues | undefined
  >()

  const [attributePropertyValues, setAttributePropertyValues] = useState<
    EbayAttributeAspectValues | undefined
  >()

  useEffect(() => {
    if (!templateId) return
    getEbayAttributeAspectValues(templateId)
      .then((res) => {
        if (res.success && res.data) {
          setAttributeAspectValues(res.data)
        }
      })
      .catch((e) => err(e))

    getEtsyAttributePropertyValues(templateId)
      .then((res) => {
        if (res.success && res.data) {
          setAttributePropertyValues(res.data)
        }
      })
      .catch((e) => err(e))
  }, [templateId])

  const handleMergeAttributes = () => {
    if (
      !templateId ||
      !mergeAttributeSource ||
      !mergeAttributeTarget ||
      !mergeAttributesAction
    ) {
      return
    }
    mergeTemplateAttributes({
      templateId: templateId,
      source: mergeAttributeSource.attribute.name,
      target: mergeAttributeTarget.attribute.name,
      action: mergeAttributesAction,
      deleteSource: mergeAttributesDeleteSource,
    })
      .then((res) => {
        handleAlert(setAlert, res, 'Merged Template Attributes')
      })
      .catch((e) => err(e))
  }

  const handleUpdate = () => {
    setUpdate(update + 1) // increase update state to cause render
  }

  const pushTemplateAttributes = (attribute: SetProductTemplateAttribute) => {
    // check null doesn't exist
    const attributeExists = templateAttributes.find(
      (templateAttribute) =>
        templateAttribute.attribute.name === attribute.attribute.name,
    )

    if (attributeExists) {
      setAlert({
        open: true,
        message:
          'Empty Attribute exists. Please enter a name before adding new attributes.',
        severity: 'warning',
      })
      return
    }
    setTemplateAttributes(templateAttributes.concat([attribute]))
  }

  const attributeNames = templateAttributes.map((a) => a.attribute.name)

  const reIndexVariations = (
    newAttributes: SetProductTemplateAttribute[],
  ): SetProductTemplateAttribute[] => {
    return newAttributes.map(
      (a, i): SetProductTemplateAttribute => ({
        attribute: { ...a.attribute, index: i },
        attributeOptions: a.attributeOptions,
      }),
    )
  }

  const handleTemplateAttributeChange = (
    attribute: SetProductTemplateAttribute | undefined,
    name: string,
  ) => {
    let newAttributes = templateAttributes.slice() // copy
    const index = newAttributes.findIndex((a) => a.attribute.name === name)

    // Update
    if (attribute && index > -1) {
      newAttributes[index] = attribute
      // Add
    } else if (attribute && index === -1) {
      newAttributes.push(attribute) // add
      // Delete
    } else if (!attribute) {
      newAttributes.splice(index, 1) // delete
      newAttributes = reIndexVariations(newAttributes)
    }
    setTemplateAttributes(newAttributes)
  }

  const handleTemplateAttributeIndexChange = (
    index: number,
    newIndex: number,
  ) => {
    let newAttributes = templateAttributes.slice() // copy
    const attribute = newAttributes[index]

    if (attribute) {
      // update index
      const tempAttribute = newAttributes[newIndex] // store temp
      newAttributes[newIndex] = attribute // update new index
      if (tempAttribute) newAttributes[index] = tempAttribute // set old index to temp
      // re-index

      // preserve indexes, may not be 0 based.
      newAttributes = newAttributes.map(
        (a, i): SetProductTemplateAttribute => ({
          attribute: { ...a.attribute, index: i },
          attributeOptions: a.attributeOptions,
        }),
      )
      setTemplateAttributes(newAttributes)
    }
  }

  const addAttributeToTitle = (a: SetProductTemplateAttribute) => {
    const attributeName = a.attribute.name
    const richTextValue = richText
    setRichText(false)
    setTitle(title + ' ' + getAttributeTag(attributeName))
    void wait(100).then(() => setRichText(richTextValue)) // hacky way to rerender
  }
  const addAttributeToDescription = (a: SetProductTemplateAttribute) => {
    const attributeName = a.attribute.name
    // const richTextValue = richText
    // setRichText(false)

    setDescription(
      description +
        '\n' +
        attributeName +
        ': ' +
        getAttributeTag(attributeName),
    )
    setDescriptionUpdates(descriptionUpdates + 1)

    // void wait(100).then(() => setRichText(richTextValue)) // hacky way to rerender
  }

  const handleMergeAttributesPress = (a: SetProductTemplateAttribute) => {
    setMergeAttributeSource(a)
    setMergeAttributesModalOpen(true)
  }

  const handleVariantAttributeChange = (
    templateAttributeNames: { name: string }[],
  ) => {
    if (!templateId) return
    const newTemplateVariationAttributes = templateAttributeNames.map(
      (templateAttributeName, i) => ({
        name: templateAttributeName.name,
        index: i,
        templateId: templateId,
      }),
    )
    setTemplateVariationAttributes(newTemplateVariationAttributes)
  }

  const handleSizeWeightChange = (
    name: SizeWeightChangeEnum,
    value: string | number | undefined,
  ) => {
    const v = value ? value + '' : undefined
    switch (name) {
      case SizeWeightChangeEnum.WEIGHT_UNIT:
        setWeightUnit(v || '')
        break
      case SizeWeightChangeEnum.SIZE_UNIT:
        setSizeUnit(v || '')
        break
      case SizeWeightChangeEnum.WEIGHT:
        setWeight(v)
        break
      case SizeWeightChangeEnum.L:
        setLength(v)
        break
      case SizeWeightChangeEnum.W:
        setWidth(v)
        break
      case SizeWeightChangeEnum.H:
        setHeight(v)
        break
      default:
        console.log('Not found.')
    }
  }

  const handleDelete = async () => {
    if (!templateId) return

    if (confirmDeleteName !== name) {
      setAlert({
        open: true,
        severity: 'error',
        message: 'Template Name does not match.',
      })
      return
    }

    const res = await deleteProductTemplate(templateId)
    if (res.data) {
      handleAlert(
        setAlert,
        res,
        'Deleted Template. Navigating to Templates Page.',
      )
      await wait(1000)
      history.push('/templates')
    }
  }

  const handleViewTemplates = () => {
    history.push('/templates')
  }

  const handleCreateProduct = () => {
    history.push(`/newProduct/${templateId}`)
  }

  const handleDeleteRequest = async () => {
    setDeleteModalOpen(true)
  }

  const handleDuplicateTemplate = async () => {
    if (!templateId) return
    duplicateTemplate(templateId)
      .then(async (res) => {
        if (res.success && res.data) {
          const templateId = res.data
          setAlert({
            open: true,
            message:
              'Successfully duplicated template. Navigating to new Template',
            severity: 'success',
          })
          await wait(2000)
          setAlert({ open: false, severity: 'success', message: '' })
          history.push(`/template/${templateId}`)
        }
      })
      .catch((e) => {
        if (e?.response?.data?.message?.includes('already exists')) {
          setAlert({
            open: true,
            message: e.response.data.message,
            severity: 'error',
          })
          return
        }
        setAlert({
          open: true,
          message: 'Failed to duplicate template.',
          severity: 'error',
        })
      })
  }

  const handleSave = async () => {
    setLoading(true)
    if (!templateId) return

    const template: ProductTemplateInput = {
      id: templateId,
      name,
      typeDescription,
      typeNotes,
      isStock,
      tags: tags.join(','),
      title,
      description,
      weightUnit,
      weight: weight ? parseFloat(weight) : undefined,
      sizeUnit,
      width: width ? parseFloat(width) : undefined,
      length: length ? parseFloat(length) : undefined,
      height: height ? parseFloat(height) : undefined,
    }

    // add default type as string. TODO: Add types?
    const attributes: SetProductTemplateAttribute[] = templateAttributes?.map(
      (attr) => ({
        ...attr,
        type: ProductTemplateAttributeTypesEnum.TEXT,
      }),
    )

    // order default templates

    const result = await updateProductTemplate(
      template,
      attributes,
      templateVariationAttributes,
    )
    setLoading(false)
    if (result.success && result.data) {
      setAlert({
        open: true,
        message: 'Template Saved.',
        severity: 'success',
      })
      setTemplateState(result.data)
    } else {
      setAlert({
        open: true,
        message: result.message,
        severity: 'error',
      })
    }
  }

  /* Get Product Templates */
  useEffect(() => {
    // May need to re-add this.
    // isMounted.current &&
    if (templateId) {
      getProductTemplate(templateId)
        .then((res) => {
          if (!res.data) {
            return
          }
          const template = res.data
          setTemplateState(template)
        })
        .catch((e) => err(e))
      return () => {
        isMounted.current = false
      }
    }
  }, [templateId, update])

  const setTemplateState = (getTemplate: GetProductTemplate) => {
    const { template, attributes, variations } = getTemplate
    // Sort and re-index
    const sortedAttributes = attributes.sort(
      (a, b) => a.attribute.index - b.attribute.index,
    )

    if (!template) return
    setTemplate(getTemplate)
    setName(template.name)
    setTypeDescription(template.typeDescription || '')
    setTypeNotes(template.typeNotes || '')
    setIsStock(template.isStock || false)
    setTypeTags(template.tags?.split(',') || [])
    setTemplateAttributes(sortedAttributes)
    setTemplateVariationAttributes(variations || [])

    setTitle(template.title || '')
    setDescription(template.description || '')
    setTags(template.tags ? template.tags?.split(',') : [])
    setWeightUnit(template.weightUnit || '')
    setSizeUnit(template.sizeUnit || '')
    setWeight(template.weight ? template.weight + '' : undefined)
    setLength(template.length ? template.length + '' : undefined)
    setWidth(template.width ? template.width + '' : undefined)
    setHeight(template.height ? template.height + '' : undefined)
  }

  /* Get Integration */
  useEffect(() => {
    getAvailableIntegrations()
      .then((res) => {
        if (res.success) {
          setAvailableIntegrations(res.data || [])
        }
      })
      .catch((e) => err(e))
    getIntegrations({ templateId })
      .then((res) => {
        if (res.success && res.data) {
          setIntegrations(res.data)
        }
      })
      .catch((e) => err(e))
  }, [templateId])

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <NavBar />
      <Container sx={{ mt: 4 }} maxWidth={'xl'}>
        <Component>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button
                    onClick={handleViewTemplates}
                    variant="solid"
                    size="sm"
                  >
                    <ArrowBackIcon
                      fontSize="small"
                      sx={{ paddingRight: '0.25em' }}
                    />
                    Templates
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleCreateProduct}
                    variant="solid"
                    size="sm"
                    endDecorator={<Add fontSize="small" />}
                  >
                    Create Product
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} pt={1} pb={3}>
              <Typography level="h3">
                <strong>{name || 'Template'}</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Tabs
                tabs={['template', 'channels', 'details']}
                selected={tab}
                onSelect={setSelectedTab}
                panels={[
                  <>
                    <Grid container spacing={6} sx={{ pt: 2 }}>
                      {/* Details */}
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={12}>
                            <Typography level="h4" textAlign="center">
                              Details
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                              <Grid item sx={{ mb: '-12px' }}>
                                <Button
                                  size="sm"
                                  variant="plain"
                                  color="neutral"
                                  onClick={() => setRichText(!richText)}
                                  endDecorator={
                                    richText ? (
                                      <EditNoteIcon fontSize="small" />
                                    ) : (
                                      <EditIcon fontSize="small" />
                                    )
                                  }
                                >
                                  <Typography level="body-sm">
                                    {richText ? 'Rich Text' : 'Plain Text'}
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            {richText && title !== undefined ? (
                              <ProductDescriptionEditor
                                key={`title-editor-${attributeNames.length}`}
                                title="Title"
                                oneLine={true}
                                richText={richText}
                                value={title}
                                onChange={(title) => setTitle(title)}
                                onUpdate={(title) => setTitle(title)}
                                attributeNames={attributeNames}
                                templateAttributes={templateAttributes}
                              />
                            ) : (
                              <FloatLabelInput
                                label="Title"
                                value={title}
                                fullWidth
                                onChange={(title) => setTitle(title)}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            {description !== undefined && (
                              <Box sx={{ position: 'relative' }}>
                                <ProductDescriptionEditor
                                  key={`description-editor-${descriptionUpdates}-${attributeNames.length}`}
                                  title="Description"
                                  richText={richText}
                                  value={description}
                                  onChange={(description) => {
                                    setDescription(description)
                                  }}
                                  onUpdate={(description) => {
                                    setDescription(description)
                                  }}
                                  attributeNames={attributeNames}
                                  templateAttributes={templateAttributes}
                                />

                                {/* <Dropdown>
                                      <MenuButton
                                        onClick={handleBulkActionsMenuClick}
                                        sx={{
                                          position: 'absolute',
                                          right: '0.4em',
                                          bottom: '0.4em',
                                        }}
                                        slots={{ root: IconButton }}
                                        slotProps={{
                                          root: {
                                            variant: 'plain',
                                            color: 'neutral',
                                          },
                                        }}
                                      >
                                        <AlternateEmailIcon
                                          fontSize="small"
                                          color="primary"
                                          sx={{ opacity: 0.7 }}
                                        />
                                      </MenuButton>
                                      <Menu
                                        sx={{
                                          maxHeight: '400px',
                                          overflow: 'scroll',
                                        }}
                                      >
                                        {bulkActionOptions.map((option) => (
                                          <MenuItem
                                            key={option}
                                            data-option={option}
                                            onClick={(e) =>
                                              handleBulkActionsOptionClick(e)
                                            }
                                          >
                                            <ListItemContent>
                                              <Typography level="title-sm">
                                                <strong>{option}</strong>
                                              </Typography>
                                            </ListItemContent>
                                          </MenuItem>
                                        ))}
                                        {templateAttributes.map((a) => (
                                          <MenuItem
                                            key={a.attribute.name}
                                            data-option={a.attribute.name}
                                            onClick={(e) =>
                                              addAttributeToDescription(a)
                                            }
                                          >
                                            <ListItemContent>
                                              <Typography level="body-sm">
                                                <strong>
                                                  @{a.attribute.name}
                                                </strong>
                                              </Typography>
                                            </ListItemContent>
                                          </MenuItem>
                                        ))}
                                      </Menu>
                                    </Dropdown> */}
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <ProductTags tags={tags} onChange={setTags} />
                          </Grid>

                          <Grid item xs={12}>
                            <ProductSizeWeightInput
                              width={width}
                              length={length}
                              height={height}
                              weight={weight}
                              sizeUnit={sizeUnit}
                              weightUnit={weightUnit}
                              onChange={handleSizeWeightChange}
                              template
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <ProductTemplateDefaultAttributeInputField
                              name={'Vendor'}
                              displayLabel={true}
                              attributes={templateAttributes}
                              onChange={handleTemplateAttributeChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ProductTemplateDefaultAttributeInputField
                              name={'Location'}
                              displayLabel={true}
                              attributes={templateAttributes}
                              onChange={handleTemplateAttributeChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ProductTemplateDefaultAttributeInputField
                              name={'Warehouse'}
                              displayLabel={true}
                              attributes={templateAttributes}
                              onChange={handleTemplateAttributeChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ProductTemplateDefaultAttributeInputField
                              name={'Bin'}
                              displayLabel={true}
                              attributes={templateAttributes}
                              onChange={handleTemplateAttributeChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Attributes */}
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={12}>
                            <Grid container justifyContent="center" spacing={0}>
                              <Grid item xs={12}>
                                <Typography level="h4" textAlign="center">
                                  Attributes
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Grid container justifyContent="flex-end">
                                  <Grid item mr={2} mb={-1}>
                                    <Typography
                                      component="div"
                                      level="body-sm"
                                      onClick={() =>
                                        setReorderAttributes(!reorderAttributes)
                                      }
                                      sx={{ cursor: 'pointer' }}
                                      endDecorator={
                                        <Switch checked={reorderAttributes} />
                                      }
                                    >
                                      Re-order
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <ProductTemplateDefaultAttributeInputField
                              name={'Brand'}
                              attributes={templateAttributes}
                              onChange={handleTemplateAttributeChange}
                            />
                          </Grid>

                          {/* <Grid item xs={12}>
                            <ProductTemplateDefaultAttributeInputField
                              name={'Category'}
                              attributes={templateAttributes}
                              onChange={handleTemplateAttributeChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ProductTemplateDefaultAttributeInputField
                              name={'Condition'}
                              attributes={templateAttributes}
                              disabled={true}
                              onChange={handleTemplateAttributeChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ProductTemplateDefaultAttributeInputField
                              name={'Condition Description'}
                              attributes={templateAttributes}
                              onChange={handleTemplateAttributeChange}
                            />
                          </Grid> */}

                          {editableAttributes?.map((attribute, i) => {
                            const editableAttributeElement = (
                              <ProductTemplateAttributeInputField
                                key={attribute.attribute.name}
                                attribute={attribute}
                                allowDelete
                                onChange={(a) =>
                                  handleTemplateAttributeChange(
                                    a,
                                    attribute.attribute.name,
                                  )
                                }
                                addToTitle={(a) => addAttributeToTitle(a)}
                                addToDescription={(a) =>
                                  addAttributeToDescription(a)
                                }
                                mergeAttributes={(a) =>
                                  handleMergeAttributesPress(a)
                                }
                                disabled={reorderAttributes}
                                channelOptions={{
                                  ebay: attributeAspectValues?.[
                                    attribute.attribute.name
                                  ],
                                  etsy: attributePropertyValues?.[
                                    attribute.attribute.name
                                  ],
                                }}
                              />
                            )
                            if (reorderAttributes) {
                              return (
                                <Grid
                                  item
                                  xs={12}
                                  key={`${attribute.attribute.name}`}
                                >
                                  <Draggable
                                    id={`${attribute.attribute.name}`}
                                    index={attribute.attribute.index}
                                    onChange={
                                      handleTemplateAttributeIndexChange
                                    }
                                  >
                                    {editableAttributeElement}
                                  </Draggable>
                                </Grid>
                              )
                            }
                            return (
                              <Grid
                                item
                                xs={12}
                                key={`${attribute.attribute.name}`}
                              >
                                {editableAttributeElement}
                              </Grid>
                            )
                          })}
                          <Grid item xs={12}>
                            <Grid container justifyContent="flex-end">
                              <Grid item>
                                <Button
                                  onClick={() =>
                                    pushTemplateAttributes(
                                      newAttribute({
                                        index: templateAttributes.length - 1,
                                      }),
                                    )
                                  }
                                  variant="soft"
                                  color="neutral"
                                  endDecorator={<Add fontSize="small" />}
                                >
                                  Add Attribute
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Variations */}
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={3}>
                          <Grid item xs={12}>
                            <Typography level="h4" textAlign="center">
                              Variations
                            </Typography>
                            <Typography level="body-xs" textAlign="center">
                              Select attributes that products of this template
                              can vary on. SKU, Quantity, Price, and Cost are
                              included by default.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              value={templateVariationAttributes}
                              isOptionEqualToValue={(option, value) =>
                                option.name === value.name
                              }
                              options={templateAttributes.map(
                                (templateAttribute, i) => ({
                                  name: templateAttribute.attribute.name,
                                  index: i,
                                }),
                              )}
                              onChange={(e, value) =>
                                handleVariantAttributeChange(value)
                              }
                              getOptionLabel={(option) => option.name}
                              placeholder="Attributes"
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Buttons */}
                      <Grid item xs={12}>
                        <Grid
                          container
                          justifyContent="space-between"
                          spacing={2}
                        >
                          <Grid item sx={{ float: 'left' }}>
                            <Button
                              variant="solid"
                              onClick={handleDeleteRequest}
                              color="danger"
                              endDecorator={
                                <DeleteOutlineIcon fontSize="small" />
                              }
                            >
                              Delete
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="soft"
                              onClick={handleDuplicateTemplate}
                              endDecorator={
                                <ContentCopyIcon fontSize="small" />
                              }
                            >
                              Duplicate
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="solid"
                              onClick={handleSave}
                              disabled={loading}
                              endDecorator={<SaveIcon fontSize="small" />}
                              loading={loading}
                            >
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>,
                  <>
                    <Grid container>
                      {template ? (
                        <Tabs
                          tabs={availableIntegrations}
                          selected={channel}
                          onSelect={setSelectedChannel}
                          panels={[
                            <>
                              <EbayIntegrationsComponent
                                integration={integrations.ebay}
                                template={template}
                                onUpdate={handleUpdate}
                              ></EbayIntegrationsComponent>
                            </>,
                            // <>
                            //   <EtsyTemplateIntegrationComponent
                            //     integration={integrations.etsy}
                            //     template={template}
                            //     onUpdate={handleUpdate}
                            //   />
                            // </>,
                            <>
                              <EtsyIntegrationsComponent
                                template={template}
                                onUpdate={() => {
                                  console.log('Update')
                                  handleUpdate()
                                }}
                              />
                            </>,
                            <>
                              <SquareIntegrationsComponent
                                template={template}
                                onUpdate={handleUpdate}
                              ></SquareIntegrationsComponent>
                            </>,
                            <>
                              <ShopifyIntegrationsComponent
                                template={template}
                                onUpdate={handleUpdate}
                              ></ShopifyIntegrationsComponent>
                            </>,
                            <>
                              <BigCommerceIntegrationsComponent
                                template={template}
                                onUpdate={handleUpdate}
                              ></BigCommerceIntegrationsComponent>
                            </>,
                            <>
                              <CloverIntegrationsComponent
                                template={template}
                                onUpdate={handleUpdate}
                              ></CloverIntegrationsComponent>
                            </>,
                            <>
                              <CSVIntegrationsComponent
                                template={template}
                                onUpdate={handleUpdate}
                              ></CSVIntegrationsComponent>
                            </>,
                          ]}
                        />
                      ) : null}
                    </Grid>
                  </>,
                  <>
                    <Grid container spacing={3} justifyContent="center">
                      <Grid item xs={12} md={8}>
                        <LabelInput
                          label="Name"
                          required
                          value={name}
                          fullWidth
                          onChange={(v) => {
                            setName(v)
                            setNameError(v ? '' : 'Name is required.')
                          }}
                          onBlur={() =>
                            setNameError(name ? '' : 'Name is required.')
                          }
                          errorText={nameError}
                          onClick={() => {
                            setNameError('')
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <LabelTextarea
                          label="Template Details"
                          minRows={10}
                          value={typeDescription}
                          onChange={(v) => setTypeDescription(v)}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <LabelTextarea
                          label="Notes"
                          minRows={5}
                          value={typeNotes}
                          onChange={(v) => setTypeNotes(v)}
                        />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        {/* <ProductTags tags={typeTags} onChange={setTypeTags} /> */}
                      </Grid>
                      {isSuperAdmin(roleId) ? (
                        <Grid item xs={12} md={8}>
                          <Grid container>
                            <Grid item>
                              <Typography
                                component="div"
                                level="title-md"
                                endDecorator={
                                  <Switch
                                    checked={isStock}
                                    onChange={() => setIsStock(!isStock)}
                                  ></Switch>
                                }
                              >
                                <strong>Is Stock Template</strong>
                              </Typography>
                              <Typography level="body-xs">
                                Allow this template to be added to the Template
                                Library
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between">
                          <Button
                            variant="solid"
                            onClick={handleDelete}
                            color="danger"
                          >
                            Delete
                          </Button>
                          <Button variant="solid" onClick={handleSave}>
                            Save
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </>,
                ]}
              ></Tabs>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ height: '50px' }} />
          </Grid>
        </Component>
        <Box sx={{ height: '250px' }} />
        <Alert
          alert={alert}
          onClose={() => setAlert({ ...alert, open: false })}
        ></Alert>
        <Modal
          open={deleteModalOpen}
          title={`Delete Template "${name}"?`}
          onSubmit={handleDelete}
          onClose={() => setDeleteModalOpen(false)}
        >
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Typography>
                <strong>Warning</strong>: Deleting this template will delete all
                Template Integrations, Configurations, and Products
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Type the name of the Template to confirm and continue
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LabelInput
                value={confirmDeleteName}
                onChange={setConfirmDeleteName}
                label="Template Name"
                placeholder={name}
              />
            </Grid>
          </Grid>
        </Modal>

        <Modal
          open={mergeAttributesModalOpen}
          title={`Merge Attribute ${mergeAttributeSource?.attribute.name}`}
          onSubmit={handleMergeAttributes}
          onClose={() => setMergeAttributesModalOpen(false)}
        >
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              <Typography>Select Attribute to Merge into</Typography>
              <Typography level="body-xs">
                Attribute options will be copied to selected attribute. <br />
                All Product values will be merged.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SingleSelect
                label="Target Attribute"
                freeSolo={false}
                value={mergeAttributeTarget?.attribute.name || ''}
                options={editableAttributes
                  .filter(
                    (a) =>
                      a.attribute.id &&
                      a.attribute.name !== mergeAttributeSource?.attribute.name,
                  )
                  .map((a) => a.attribute.name)}
                onChange={(attributeName) => {
                  const selectedAttribute = editableAttributes?.find(
                    (editableAttribute) =>
                      editableAttribute.attribute.name === attributeName,
                  )
                  if (!selectedAttribute) return
                  setMergeAttributeTarget(selectedAttribute)
                }}
              ></SingleSelect>
            </Grid>

            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mergeAttributesAction === 'fill'}
                      onClick={() => setMergeAttributesAction('fill')}
                    />
                  }
                  label={
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography level="body-md" component="span">
                          Fill
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography level="body-xs" component="span">
                          Fill in missing values
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mergeAttributesAction === 'replace'}
                      onClick={() => setMergeAttributesAction('replace')}
                    />
                  }
                  label={
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography level="body-md" component="span">
                          Replace
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography level="body-xs" component="span">
                          Replace values with defined values
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mergeAttributesAction === 'overwrite'}
                      onClick={() => setMergeAttributesAction('overwrite')}
                    />
                  }
                  label={
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography level="body-md" component="span">
                          Overwrite
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography level="body-xs" component="span">
                          Overwrite values
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography>Delete Source Attribute</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mergeAttributesDeleteSource}
                      onClick={() =>
                        setMergeAttributesDeleteSource(
                          !mergeAttributesDeleteSource,
                        )
                      }
                    />
                  }
                  label={
                    <Typography level="body-md" component="span">
                      Delete
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        </Modal>
      </Container>
    </div>
  )
}
